<div class="root-container">
  <mat-toolbar color="primary">
    <div>
      <span>any2bib</span>
    </div>
  </mat-toolbar>


  <div class="content-container">
    <h1 class="mat-display-1">Generating BibTeX entries for a book ISBN, paper DOI or website URL.</h1>


    <div class="infoForm">
        <mat-form-field appearance="standard" class="entry-input">
          <mat-label>ISBN, DOI, URL</mat-label>
          <label>
            <input (keyup.enter)="processClick(null)" matInput [(ngModel)]="enteredInfo">
          </label>
        </mat-form-field>

        <button class="entry-button" mat-raised-button color="primary" matRipple (click)="processClick($event)">Generate BibTeX</button>
    </div>

    <p class="error-message" [hidden]="error == null">
      {{error}}
    </p>

    <div class="bibtex-result" [hidden]="!generatedBibtex">
      <pre><code id="bibtex-result-code">{{generatedBibtex}}
      </code></pre>

      <button mat-stroked-button (click)="copyToClipboard()">Copy to clipboard</button>
    </div>

  </div>

  <div class="footer">
<!--    <ng-adsense></ng-adsense>-->
    <p>© 2020 <a href="https://www.aesteon.com">Aesteon</a></p>
    <a href="https://aesteon.com/#contact">Hire us</a>
  </div>

</div>
