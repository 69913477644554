import {Component} from '@angular/core';
import {IsbnDoiService} from './isbn-doi.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable, Subject} from 'rxjs';
import {DatePipe} from '@angular/common';

import lescape from 'escape-latex';
import {GoogleAnalyticsService} from 'ngx-google-analytics';
import {MakerService} from './maker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'any2bib';
  generatedBibtex: string;
  enteredInfo = 'https://doi.org/10.1038/s41592-018-0005-3';
  error: string;

  private isbnRegex: RegExp = /^(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)[\d-]+$/;
  private urlRegex: RegExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  private doiRegex: RegExp = /\b(10[.][0-9]{4,}(?:[.][0-9]+)*\/(?:(?!["&'<>])\S)+)\b/;

  constructor(private makerservice: MakerService, private clipboard: Clipboard, private snackbar: MatSnackBar,
              private datePipe: DatePipe, private gaService: GoogleAnalyticsService) {
  }


  public processClick(event): void {
    this.error = null;
    this.generatedBibtex = null;

    // Try to discover what type of input we received.
    // This is done by means of REGEX patterns
    if (this.isbnRegex.test(this.enteredInfo)) {
      // An ISBN (book identifier) was entered. Send the input type to Google Analytics
      this.gaService.event('isbn2bib', null, 'Type');

      // Try to create a BibTeX entry for this ISBN
      this.makerservice.makeBibtexFromIsbn(this.enteredInfo).subscribe(bibtex => {
        this.generatedBibtex = bibtex;
      }, error => {
        this.error = error;
      });
    } else if (this.doiRegex.test(this.enteredInfo)) {
      // An DOI (paper identifier) was entered. Send the input type to Google Analytics
      this.gaService.event('doi2bib', null, 'Type');
      console.log('Found a DOI link');

      const bareDoi = this.doiRegex.exec(this.enteredInfo)[0];
      console.log('Found `bare` DOI link to be ' + bareDoi);

      // Try to create a BibTeX entry for this DOI
      this.makerservice.makeBibtexFromDoi(bareDoi).subscribe(bibtex => {
        this.generatedBibtex = bibtex;
      }, error => {
        this.error = error;
      });
    } else if (this.urlRegex.test(this.enteredInfo)) {
      // An URL (web identifier) was entered. Send the input type to Google Analytics
      this.gaService.event('url2bib', null, 'Type');
      console.log('Detected a URL entry');
      const url = this.enteredInfo;

      // Try to create a BibTeX entry for this URL
      this.error = 'URL entries are not fully supported yet.';
      this.generatedBibtex = `@online{AesteonEntry,
  url = {${url}},
  urldate = {${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}}
}`;
    } else {
      // An unknown input was entered. Send the input type to Google Analytics
      this.gaService.event('unknown2bib', null, 'Type');
      console.log(this.enteredInfo);
      this.error = 'Info does not have ISBN, DOI or URL format.';
    }
  }

  copyToClipboard(): void {
    // Send this event to Google Analytics
    this.gaService.event('copy2clipboard');

    // Copy to clipboard
    this.clipboard.copy(this.generatedBibtex);

    // Display a notification
    this.snackbar.open('Copied BibTeX to clipboard', null, {duration: 2000});
  }
}
