import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsbnDoiService {
  isbnApi = 'https://www.googleapis.com/books/v1/volumes';
  doiApi = 'https://doi.org/';
  corsAnywhere = 'https://cors-anywhere.herokuapp.com/';

  constructor(private http: HttpClient) {

  }

  getInfoForIsbn(isbn: string): Observable<GoogleBooksResponse> {
    return this.http.jsonp<GoogleBooksResponse>(this.isbnApi + '?q=isbn:' + isbn, 'callback');
    // return this.http.get<GoogleBooksResponse>('https://www.googleapis.com/books/v1/volumes' + '?q=isbn:' + isbn);
  }

  getInfoForDoi(doi: string): Observable<string> {
    return this.http.get(this.doiApi + doi, {
      headers: {
        Accept: 'application/x-bibtex; charset=utf-8',
      },
      responseType: 'text',
    });
  }

  // getInfoForUrl(url: string): Observable<UrlInfoResponse> {
  //   const response = new Subject<UrlInfoResponse>();
  //   from(inspector('http://bit.ly/2ePIrDy')).subscribe(value => {
  //     return;
  //   }, error => {
  //     return;
  //   });
  //
  //   return response;
  // }

}

export interface GoogleBooksResponse {
  items: GoogleBook[];
  totalItems: number;
}


export interface GoogleBook {
  volumeInfo: {
    authors: string[];
    title: string;
    publisher: string;
    publishedDate: string;
  };
}

export interface UrlInfoResponse {
  author: string;
}

