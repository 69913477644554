import { Injectable } from '@angular/core';
import {IsbnDoiService} from './isbn-doi.service';
import {Observable, Subject} from 'rxjs';
import lescape from 'escape-latex';

@Injectable({
  providedIn: 'root'
})
export class MakerService {

  constructor(private getterService: IsbnDoiService) { }

  public makeBibtexFromIsbn(isbn: string): Observable<string> {
    const isbnResponse = new Subject<string>();

    console.log(isbn);

    this.getterService.getInfoForIsbn(isbn).subscribe(resp => {
      console.log(resp);
      if (resp.totalItems < 1) {
        isbnResponse.error('Book could not be found using this ISBN.');
        return;
      }
      const book = resp.items[0].volumeInfo;
      console.log(book);
      const date = new Date(Date.parse(book.publishedDate));
      const bibtexResult = `@book{AesteonEntry,
  title = {${book.title}},
  author = {${lescape(book.authors.join(' and '))}},
  publisher = {${lescape(book.publisher)}},
  year = ${date.getUTCFullYear()},
  month = ${date.getMonth()},
  isbn = {${isbn}}
}`;
      isbnResponse.next(bibtexResult);
    }, error => {
      isbnResponse.error('Failed to retrieve ISBN info. Please try again later.');
      console.log(error);
    });

    return isbnResponse;
  }


  public makeBibtexFromDoi(doi: string): Observable<string> {
    const doiResponse = new Subject<string>();

    this.getterService.getInfoForDoi(doi).subscribe(response => {
      console.log(response);
      doiResponse.next(response);
    }, error => {
      console.log(error);
      doiResponse.error('DOI link could not be resolved to an article');
    });

    return doiResponse;
  }
}
