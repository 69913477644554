import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HttpClientJsonpModule} from '@angular/common/http';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatToolbarModule} from '@angular/material/toolbar';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DatePipe} from '@angular/common';
import {NgxGoogleAnalyticsModule} from 'ngx-google-analytics';
// import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientJsonpModule,
    NoopAnimationsModule,
    MatButtonModule,
    RouterModule,
    MatRippleModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSnackBarModule,
    MatInputModule,
    MatRippleModule,
    HttpClientModule,
    FormsModule,
    MatButtonModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    ClipboardModule,
    NgxGoogleAnalyticsModule.forRoot('UA-127031350-4')
    // AdsenseModule.forRoot({
    //   adClient: 'ca-pub-7640562161899788',
    //   adSlot: 7259870550,
    // }),
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
